import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgPager from 'lightgallery/plugins/pager';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import './lightgallery.scss';
import './fonts/lg.woff';

export class Gallery {
    constructor(target) {
        this.settings = {
            plugins: [lgZoom, lgThumbnail, lgPager, lgFullscreen],
            // licenseKey: 'your_license_key',
            selector: 'a',
            mode: 'lg-fade',
            download: false,
            thumbnail: true,
            thumbMargin: 10,
            toggleThumb: true,
            exThumbImage: 'data-thumb', // also use in markup: <a href="<?= $galleryImage; ?>" data-thumb="<?= $galleryThumb; ?>">
            thumbWidth: 140,
            thumbHeight: 100,
            fullScreen: true,
            zoom: true,
            showZoomInOutIcons: true,
            counter: true,
            share: false,
            actualSize: false,
            autoplay: false,
            autoplayControls: false,
        }
        this.element = $(target);

        this.init();
    }

    init() {
        lightGallery(this.element[0], this.settings);
    }
}

// Init on document ready
let gallery;
$(function () {
    $('.js-gallery').each(function() {
        gallery = new Gallery(this);
    });
});
