import './button-scroll-up.scss';

export class BtnScrollUp {
    constructor(target) {
        this.element = $(target);

        this.elementSelector = '.btn-scroll-up';

        this.init();
    }

    init() {
        $(document).on('click', this.elementSelector, this.scrollUp.bind(this));
        $(window).on('scroll load', this.showHide.bind(this));
    }

    scrollUp() {
        $('html, body').stop(true, false).animate({
            scrollTop: 0,
        }, 300);
    }

    showHide() {
        let documentHeight  = $(document).innerHeight();
        let scrollTop       = $(window).scrollTop();
        let show            = false;
        let isOver33Percent = ((scrollTop * 100) / documentHeight) > 33;

        if (isOver33Percent) {
            show = true;
        }

        this.element[show ? 'addClass' : 'removeClass']('is-visible');
    }
}

// Init on document ready
let btnScrollUp;
$(function () {
    $('.btn-scroll-up').each(function() {
        btnScrollUp = new BtnScrollUp(this);
    });
});

