import stickybits from 'stickybits';
import './stickybits.scss';

export class Sticky {
    constructor(target) {
        this.selector = '.js-sticky';

        this.settings = {
            stickyBitStickyOffset: 0,
            useStickyClasses: true,
        }
        this.element = $(target);

        this.init();
    }

    init(update = false) {
        let that = this;

        setTimeout(() => {
            const sticky = stickybits(that.selector, that.settings);

            if (update) {
                sticky.update();
            }
        }, 50);
    }
}

// Init on document ready
let sticky;
$(function () {
    $('.js-sticky').each(function(index) {
        sticky = new Sticky(this);
    });
});
