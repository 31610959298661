import "./header.scss";

import {disableScroll, enableScroll} from '../helpers/helpers';

export class Header {
    constructor(target) {
        this.settings = {
            transitionDuration: 250,
        };

        this.classes = {
            toggleClass: 'header__hamburger',
            openClass: 'is-open',
            activeClass: 'is-active',
            scrolledClass: 'is-scrolled',
            dropdownClass: 'nav__item--parent',
            itemLinkClass: 'nav__link',
        };

        this.element       = $(target);
        this.body          = $('body');
        this.mobileToggle  = $('.' + this.classes.toggleClass);
        this.dropdownItems = $('.' + this.classes.dropdownClass);
        this.initHeight    = this.element.find('.nav__item').first().outerHeight();
        this.scrollStart   = 0;

        this.init();
    }

    init() {
        this.mobileToggle.on('click', this.toggleMobileMenu.bind(this));
        this.dropdownItems.children('.' + this.classes.itemLinkClass).on('click', this.toggleDropdown.bind(this));

        $(window).on('resize', this.resizeHandler.bind(this));
        $(window).on('scroll load', this.scrollHandler.bind(this));
    }

    resizeHandler() {
        if ($(window).width() > 1024) {
            this.closeCurrent(true);
        }
    }

    toggleMobileMenu(event) {
        event.preventDefault();
        this.element.toggleClass(this.classes.openClass);

        if (this.element.hasClass(this.classes.openClass)) {
            disableScroll();
        } else {
            enableScroll();
        }
    }

    scrollHandler() {
        const currentPosition = window.pageYOffset || document.documentElement.scrollTop;
        this.scrollStart = (this.scrollStart === 0) ? currentPosition : this.scrollStart;

        if (currentPosition < 12) {
            this.body.removeClass(this.classes.scrolledClass);
            this.scrollStart = currentPosition;
        } else {
            this.body.addClass(this.classes.scrolledClass);
            this.scrollStart = currentPosition;
        }

        this.scrollStart = currentPosition;
    }

    toggleDropdown(event) {
        const previousOpen  = this.dropdownItems.filter('.' + this.classes.activeClass);
        const parentElement = $(event.currentTarget).closest('.nav__item');
        const element       = $(event.currentTarget);

        if (element.attr('href') === '#' || $(window).width() <= 1024) {
            event.preventDefault();
        }

        if ($(window).width() <= 1024) {
            event.preventDefault();

            if (parentElement.hasClass(this.classes.activeClass)) {
                this.animateClose(parentElement);
            } else {
                this.animateClose(previousOpen);
                this.animateOpen(parentElement);
            }
        } else {
            if (parentElement.hasClass(this.classes.activeClass)) {
                this.close(parentElement);
            } else {
                this.close(previousOpen);
                this.open(parentElement);
            }
        }
    }

    open(menuItem) {
        menuItem.addClass(this.classes.activeClass);
        menuItem.children('.' + this.classes.itemLinkClass).attr('aria-expanded', 'true');
    }

    close() {
        const currentActiveItem = this.dropdownItems.filter('.' + this.classes.activeClass);

        currentActiveItem.removeClass(this.classes.activeClass);
        currentActiveItem.children('.' + this.classes.itemLinkClass).attr('aria-expanded', 'false');
    }

    animateOpen(parentElement) {
        const parentHeight   = parentElement.outerHeight();
        const dropdownHeight = parentElement.find('.nav__list--children').outerHeight();

        const calculatedHeight = parentHeight + dropdownHeight;

        if (parentElement.is(':animated')) {
            return;
        }

        this.initHeight = parentElement.outerHeight();

        parentElement.stop().animate({
            height: calculatedHeight,
        }, this.settings.transitionDuration, () => {
            this.open(parentElement);
        });
    }

    animateClose(current) {
        const currentActiveItem = current;

        if (currentActiveItem) {
            this.close();

            setTimeout(() => {
                currentActiveItem.stop().animate({
                    height: this.initHeight,
                }, this.settings.transitionDuration);
            }, 250);
        }
    }

    closeCurrent(reset) {
        const currentOpen = this.dropdownItems.filter('.' + this.classes.activeClass);

        if (currentOpen.length > 0) {
            if (reset) {
                this.dropdownItems.removeAttr('style');
                this.dropdownItems.removeClass(this.classes.activeClass);
            } else {
                this.animateClose(currentOpen);
            }
        }
    }
}

// Init on document ready
let header;
$(function () {
    $('.js-header').each(function() {
        header = new Header(this);
    });
});
