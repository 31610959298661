import "./contact.scss";

export class Contact {
    constructor(target) {
        this.element  = $(target);
        this.phone    = $('.cta-block__content a[href*="tel:"]');
        this.email    = $('.cta-block__content a[href*="mailto:"]');
        this.formSend = $('.btn-send');
        this.init();
    }

    init() {
        this.phone.on('click', this.GAEventPhone.bind(this));
        this.email.on('click', this.GAEventEmail.bind(this));
        this.formSend.on('click', this.GAEventForm.bind(this));
    }

    GAEventPhone() {
        gtag('event', 'klikk', {
            'TheEventAction': 'Kontakt',
            'TheEventLabel': 'Telefon',
            'event_category': 'Kontakt',
  	        'event_label': 'Telefon',
        });
    }

    GAEventEmail() {
        gtag('event', 'klikk', {
            'TheEventAction': 'Kontakt',
            'TheEventLabel': 'E-post',
            'event_category': 'Kontakt',
  	        'event_label': 'E-post',
        });
    }

    GAEventForm() {
        gtag('event', 'klikk', {
            'TheEventAction': 'Kontakt',
            'TheEventLabel': 'Vorm saada',
            'event_category': 'Kontakt',
  	        'event_label': 'Vorm saada',
        });
    }
}

// Init on document ready
let contact;
$(function() {
    $('.contact').each(function() {
        contact = new Contact(this);
    });
});
