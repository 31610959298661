import 'overlayscrollbars';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import './overlayscrollbars.scss';

export class Scrollbar {
    constructor(target, options) {
        this.element  = $(target);
        this.settings = {};

        if (options) {
            this.settings = options;
        }

        return this.element.overlayScrollbars(this.settings);
    }
}
