import "./map.scss";
import { Loader } from 'google-maps';

export class LocationMap {
    constructor(target) {
        this.element      = $(target);
        this.body         = $(document.body);
        this.mapContainer = $('#map-location');
        this.locations    = {};
        this.markers      = [];
        this.infoWindow   = {};
        this.marker       = {};
        this.map          = {};
        this.mapZoom      = 14;
        this.mapCenter    = {
            lat: 59.4395296,
            lng: 24.9396884
        };
        this.loader = new Loader('AIzaSyDbEjmyPCliscBsyIBBpuQHDVg7aEJmX9g');

        this.init();
    }

    init() {

        var that = this;

        this.loader.load().then(() => {
            this.map = new google.maps.Map(this.mapContainer.get(0), {
                center: this.mapCenter,
                zoom: this.mapZoom,
                styles: [
                    {
                        "featureType": "administrative",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#444444"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.country",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.province",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            },
                            {
                                "saturation": "0"
                            },
                            {
                                "lightness": "0"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.locality",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.neighborhood",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            },
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape.man_made",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape.natural",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "saturation": "17"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape.natural",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "hue": "#91ff00"
                            },
                            {
                                "lightness": "56"
                            },
                            {
                                "saturation": "26"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "all",
                        "stylers": [
                            {
                                "saturation": -100
                            },
                            {
                                "lightness": 45
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f5d2c4"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "color": "#f5d2c4"
                            },
                            {
                                "lightness": "60"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f3f3f3"
                            },
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": "#e9f6f8"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    }
                ],
            });

            this.infoWindow = new google.maps.InfoWindow();
            this.locations  = JSON.parse(this.mapContainer.get(0).getAttribute('data-markers'));

            const markerBackground = window.estpress.assetsPath + '/img/icon-bg-marker.svg';

            if (this.locations.length) {
                this.locations.forEach((value, index) => {
                    const longitude    = that.issetAndHasValue(value[1]) ? value[1] : '';
                    const latitude     = that.issetAndHasValue(value[2]) ? value[2] : '';
                    const url          = that.issetAndHasValue(value[3]) ? value[3] : '';
                    const label        = that.issetAndHasValue(value[4]) ? value[4] : '';
                    const icon         = that.issetAndHasValue(value[5]) ? value[5] : markerBackground;
                    const title        = that.issetAndHasValue(value[6]) ? value[6] : '';
                    const text         = that.issetAndHasValue(value[7]) ? value[7] : '';
                    let markerObject = {};

                    if (longitude && latitude) {
                        markerObject = {
                            position: new google.maps.LatLng(longitude, latitude),
                            map: that.map,
                        };

                        that.markerUrl(markerObject, url);
                        that.markerLabel(markerObject, label);
                        that.markerIcon(markerObject, icon);

                        that.marker = new google.maps.Marker(markerObject);
                        that.markers.push(that.marker);

                        if (url) {
                            google.maps.event.addListener(that.marker, 'click', ((marker, index) => {
                                return () => {
                                    window.open(marker.url);
                                }
                            })(that.marker, index));
                        }

                        if (text) {
                            const content = that.markerInfoWindowContent(text, title);

                            google.maps.event.addListener(that.marker, 'click', ((marker, index) => {
                                return () => {
                                    that.infoWindow.setContent(content);
                                    that.infoWindow.open(that.map, marker);
                                }
                            })(that.marker, index));
                        }
                    }
                });
            }
            $(window).trigger('resize');

        }).catch(error => {
            console.log(error);
        });

        if (! $(document.body).hasClass('is-home')) {
            $(window).on('resize', this.updateMapCenter.bind(this));
        }
    }

    updateMapCenter() {
        this.mapCenter = {
            lat: 59.435926,
            lng: 24.905633
        };

        if (window.innerWidth <= 1050) {
            this.mapCenter = {
                lat: 59.437476,
                lng: 24.924618
            }
        }

        if (window.innerWidth <= 550) {
            this.mapCenter = {
                lat: 59.4395296,
                lng: 24.9396884
            }
        }

        const center = new google.maps.LatLng(this.mapCenter.lat, this.mapCenter.lng);
        this.map.panTo(center);
    }

    markerUrl(markerObject, url) {
        if (url) {
            return markerObject.url = url;
        }
    }

    markerLabel(markerObject, label) {
        if (label) {
            return markerObject.label = {
                text: label,
            };
        }
    }

    markerIcon(markerObject, icon) {
        if (icon) {
            let size   = new google.maps.Size(55, 54);
            let anchor = new google.maps.Point(22, 54);

            if (icon.indexOf('bg-marker') === -1) {
                size   = new google.maps.Size(34, 34);
                anchor = new google.maps.Point(17, 17);
            }

            return markerObject.icon = {
                url: icon,
                size: size,
                anchor: anchor,
            };
        }
    }

    markerInfoWindowContent(text = '', title = '') {
        if (text) {
            text = '<p>' + text + '</p>';

            if (title) {
                text = '<h4>' + title + '</h4>' + text;
            }
        }

        return text;
    }

    issetAndHasValue(value) {
        if (value !== 'undefined' && value) {
            return true;
        }

        return false;
    }
}

// Init on document ready
let googleMap;
$(function() {
    $('.js-map').each(function() {
        googleMap = new LocationMap(this);
    });
});
