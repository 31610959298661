import "lazysizes/plugins/native-loading/ls.native-loading";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";

import "./image.scss";

function imageSizeResize() {
    $('[sizes]').each(function() {
        var el    = $(this);
        var width = el.parent().innerWidth();
        el.attr('sizes', width);
    });
}
$(window).on('resize', imageSizeResize);
