import 'vanilla-cookieconsent/dist/cookieconsent.css';
import './cookie-notice.scss';
import * as CookieConsent from 'vanilla-cookieconsent';

/**
 * Settings: https://cookieconsent.orestbida.com/essential/introduction.html
 * Playground: https://playground.cookieconsent.orestbida.com/
 */

CookieConsent.run({
    // root: 'body',
    // autoShow: true,
    // disablePageInteraction: true,
    // hideFromBots: true,
    // mode: 'opt-in',
    // revision: 0,

    cookie: {
        name: 'cc_cookie',
        // domain: location.hostname,
        // path: '/',
        // sameSite: "Lax",
        // expiresAfterDays: 365,
    },

    // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
    guiOptions: {
        consentModal: {
            // layout: 'cloud inline', // 'box' | 'cloud' | 'cloud inline'
            layout: 'box', // 'box' | 'cloud' | 'cloud inline'
            // position: 'bottom center',
            position: 'bottom left',
            equalWeightButtons: true,
            flipButtons: false, // Flips buttons vertically
        },
        preferencesModal: {
            layout: 'box',
            equalWeightButtons: true,
            flipButtons: false,
        },
    },

    // onFirstConsent: ({cookie}) => {},

    // onConsent: ({cookie}) => {},

    // onChange: ({changedCategories, changedServices}) => {},

    // onModalReady: ({modalName}) => {},

    // onModalShow: ({modalName}) => {},

    // onModalHide: ({modalName}) => {},

    categories: {
        necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true,  // this category cannot be disabled
        },
        analytics: {
            autoClear: {
                cookies: [
                    {
                        name: /^_ga/,   // regex: match all cookies starting with '_ga'
                    },
                    {
                        name: '_gid',   // string: exact cookie name
                    },
                ],
            },

            // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
            services: {
                ga: {
                    label: 'Google Analytics',
                    onAccept: () => {},
                    onReject: () => {},
                },
                youtube: {
                    label: 'Youtube Embed',
                    onAccept: () => {},
                    onReject: () => {},
                },
            },
        },
        ads: {},
    },

    language: {
        default: window.estpress.currentLanguage,
        translations: window.estpress.cookieTranslations,
    },
});
