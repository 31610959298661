import 'overlayscrollbars';
import './filter-table.scss';

import { Scrollbar } from '../overlayscrollbars/overlayscrollbars';
import { setCookie } from '../helpers/helpers';

export class FilterTable {
    constructor(target) {
        this.classes = {
            sidebar: 'filter-table__sidebar',
            canvas: 'filter-table__canvas',
            canvasHouseName: 'filter-table__canvas-house-name',
            table: 'filter-table__table',
            tableBody: 'table__body',
            apartment: 'filter-table__apartment',
            highlight: 'is-highlighted',
            form: 'filter-table__form',
            cancel: 'filter-table__cancel',
            active: 'is-active',
            loading: 'is-loading',
        };

        this.element       = $(target);
        this.canvas        = this.element.find('.' + this.classes.canvas);
        this.table         = this.element.find('.' + this.classes.table);
        this.apartments    = this.element.find('.' + this.classes.apartment);
        this.dataObject    = 'data-object';
        this.dataApartment = 'data-apartment';
        this.dataHouse     = 'data-house';

        this.init();
    }

    init() {
        /**
         * On form change (filter)
         */
        $(document).on('change', '.' + this.classes.form, this.onFilterChange.bind(this));

        /**
         * On cancel click
         */
        $(document).on('click', '.' + this.classes.cancel, this.onCancel.bind(this));

        /**
         * Scrollarea calculate height
         */
        this.scrollAreaCalculate();
        $(window).on('resize', this.scrollAreaCalculate.bind(this));

        /**
         * On apartment row click
         */
        $(document).on('click', '.' + this.classes.apartment, this.onApartmentRowClick.bind(this));

        /**
         * On apartment hover
         */
        $(document).on('mouseenter mouseleave', '.' + this.classes.apartment, this.highlightOnCanvas.bind(this));

        this.filterMainScroll();
        $(window).on('resize', this.filterMainScroll.bind(this));


        this.initScrollbar();
    }

    initScrollbar() {
        this.scrollbar = new Scrollbar(this.element.find('.filter-table__table .table__body'));
    }

    currentRawURL() {
        let pathname = location.pathname;
        pathname     = pathname.replace(/\/page\/[0-9]+/, '');
        let url      = location.protocol + '//' + location.host + pathname;

        return url;
    }

    getUrlParameter(name, url = '') {
        if (! url) {
            url = location.search;
        }

        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || [null, ''])[1].replace(/\+/g, '%20')) || null;
    }

    getUrlVars() {
        let vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
            vars[key] = value;
        });
        return vars;
    }

    onFilterChange(event) {
        event.preventDefault();

        let $el = $(event.currentTarget);
        let url = this.currentRawURL() + '?' + $el.serialize();

        this.doAjaxFilter(url);
    }

    doAjaxFilter(url) {
        /**
         * Replace address in pathname also
         */
        let parseURL = new URL(url);
        let URLPaths = parseURL.pathname.split('/');
        let filtered = URLPaths.filter((el) => {
            return el != '';
        });
        let address  = filtered[filtered.length - 1];
        let isNotAll = address !== 'all' && this.getUrlParameter('address', parseURL.search) !== 'all';

        if (isNotAll) {
            let newAddress = this.getUrlParameter('address', parseURL.search);
            url            = url.replace(address, newAddress);
        }

        /**
         * AJAX
         */
        $('.' + this.classes.sidebar).addClass(this.classes.loading);

        $.get(url)
        .then((response) => {
            let $sidebar   = $('<div>').append(response).find('.' + this.classes.sidebar);
            let $tableBody = $('<div>').append(response).find('.' + this.classes.tableBody);

            $('.' + this.classes.sidebar).replaceWith($sidebar);
            this.scrollAreaCalculate($tableBody);
            this.filterMainScroll();
            this.initScrollbar();
            history.pushState('string', null, url);
        })
        .fail(function() {
            console.log('filter failed');
        });
    }

    onCancel(event) {
        let $el = $(event.currentTarget);
        let url = this.currentRawURL();

        this.doAjaxFilter(url);
    }

    scrollAreaCalculate(target) {
        let columnHeight = this.canvas.find('> *').innerHeight();
        let offset       = 300;

        if (window.innerWidth <= 1280) {
            offset = 230;
        }

        if (window.innerWidth <= 1150) {
            offset = 218;
        }

        let tableBodyHeight = columnHeight - offset;

        this.element.find('.' + this.classes.tableBody).css('max-height', tableBodyHeight); // this.element.find - because element is replaced in AJAX
    }

    filterMainScroll() {
        if (window.matchMedia('(max-width: 767px)').matches) {
            $('.filter-table__main').overlayScrollbars({});
        }
    }

    onApartmentRowClick(event) {
        const $el  = $(event.currentTarget);
        const data = JSON.parse($el.attr(this.dataObject));

        /**
         * Redirect to apartment page
         */
        if (typeof data['href'] !== 'undefined') {
            setCookie(apartmentBackURLCookie, window.location.href, 9999); // remember filters for back button (apartmentBackURLCookie from wp_enqueue_scripts hook)
            window.location.href = data['href'];
        }
    }

    highlightOnCanvas(event) {
        let eventType = event.type; // mouseenter/mouseleave
        let $el       = $(event.currentTarget);
        let data      = JSON.parse($el.attr(this.dataObject));
        let apartment = data.apartment;
        let house     = data.houseSlug;

        this.canvas.find('['+ this.dataHouse +'="'+ house +'"].' + this.classes.canvasHouseName)[eventType === 'mouseenter' ? 'addClass' : 'removeClass'](this.classes.active)
        this.canvas.find('['+ this.dataHouse +'="'+ house +'"] ['+ this.dataApartment +'="'+ apartment +'"]')[eventType === 'mouseenter' ? 'addClass' : 'removeClass'](this.classes.highlight);
    }
}

// Init on document ready
let filterTable;
$(function() {
    $('.js-filter-table').each(function() {
        filterTable = new FilterTable(this);
    });
});
