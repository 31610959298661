import "./icon.scss";

const req = require.context('./svg/', false, /^\.\/.*\.svg$/);
(req.keys()).reduce((glyphs, key) => {
    const filename = key.match(new RegExp(/[^/]+(?=\.svg$)/))[0];

    return {
        ...glyphs,
        [filename]: req(key),
    };
}, {});
