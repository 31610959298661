import './houses.scss';
export class Houses {
    constructor(target) {
        this.element = $(target);

        this.houseAttribute      = 'data-house';
        this.houseInputAttribute = 'data-name="house"';
        this.houseModal          = this.element.find(`[${this.houseAttribute}]`);
        this.enquiryModal        = $('.modal-enquiry');
        this.enquiryHouseInput   = $(`[${this.houseInputAttribute}]`);
        this.housesSelect        = this.element.find('.houses__select');
        this.housesSVG           = this.element.find('.houses__svg');

        this.init();
    }

    init() {
        this.houseModal.on('click', this.onHouseModalClick.bind(this));
        this.enquiryModal.on('hide.bs.modal', this.onModalClose.bind(this));

        this.housesSelect.on('change', this.onSelectChange.bind(this));

        this.mobileHousesPosition();
    }

    onHouseModalClick(event) {
        const target = $(event.currentTarget);
        const house  = target.attr(this.houseAttribute);

        this.enquiryHouseInput.val(house);
    }

    onModalClose(event) {
        this.enquiryHouseInput.val('');
    }

    onSelectChange(event) {
        const target = $(event.currentTarget);
        const value  = target.val();

        if (value) {
            window.location.href = value;
        }
    }

    mobileHousesPosition() {
        if (window.innerWidth <= 767) {
            this.housesSVG.scrollLeft(170);
        }
    }
}

// Init on document ready
let houses;
$(function () {
    $('.js-houses').each(function() {
        houses = new Houses(this);
    });
});
