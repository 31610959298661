import './content-gallery-slider.scss';

import { Slider } from '../slider/slider';
import { scrollToElement } from '../helpers/helpers';

export class ContentGallerySlider {
    constructor(target) {
        this.element = $(target);

        this.classes = {
            active: 'is-active',
        }

        this.listItem      = this.element.find('.content-gallery-slider__list-item');
        this.sliderContent = this.element.find('.content-gallery-slider__slider');

        this.init();
    }

    init() {
        if (this.element.find('.js-slider').length) {
            this.slider = new Slider(this.element[0].querySelector('.js-slider'));
            this.slider.on('slideChange', this.onSlideChange.bind(this));
        }

        this.listItem.on('click', this.onListItemClick.bind(this));
    }

    removeListItemActiveClass() {
        this.listItem.removeClass(this.classes.active);
    }

    isMobile() {
        if (window.innerWidth <= 767) {
            return true;
        }

        return false;
    }

    onListItemClick(event) {
        const target = $(event.currentTarget);
        const index  = target.index();

        this.removeListItemActiveClass();
        target.addClass(this.classes.active);
        this.slider.slideTo(index);

        if (this.isMobile()) {
            scrollToElement(this.sliderContent);
        }
    }

    onSlideChange(swiper) {
        this.removeListItemActiveClass();
        this.listItem.eq(swiper.activeIndex).addClass(this.classes.active);
    }
}

// Init on document ready
let contentGallerySlider;
$(function () {
    $('.js-content-gallery-slider').each(function() {
        contentGallerySlider = new ContentGallerySlider(this);
    });
});
