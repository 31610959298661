/**
 * ! PriorityNav core file is changed
 */

import './priority-nav.scss';

export class PriorityNav {
    constructor(target) {
        this.element = $(target);

        this.settings = {
            mainNavWrapper: '.header__nav',
            mainNav: '.header__nav .nav__list',
            navDropdownLabel: '<span></span><span></span><span></span>',
            breakPoint: 20 // dont't remove all menu elems,
        }

        this.init();
    }

    init() {
        priorityNav.init(this.settings);
    }
}

// Init on document ready
let prioritynav;
$(function () {
    $('.js-priority-nav').map(function() {
        prioritynav = new PriorityNav(this);
    });
});
