import './apartment.scss';

import { Slider } from '../slider/slider';

export class Apartment {
    constructor(target) {
        this.element = $(target);

        this.classes = {
            active: 'is-active',
        }

        this.listItem = this.element.find('.apartment__col-plan-list-item');

        this.init();
    }

    init() {
        if (this.element.find('.js-slider').length) {
            this.slider = new Slider(this.element[0].querySelector('.js-slider'));
            this.slider.on('slideChange', this.onSlideChange.bind(this));
        }

        this.listItem.on('click', this.onListItemClick.bind(this));
    }

    removeListItemActiveClass() {
        this.listItem.removeClass(this.classes.active);
    }

    onListItemClick(event) {
        const target = $(event.currentTarget);
        const index  = target.index();

        this.removeListItemActiveClass();
        target.addClass(this.classes.active);
        this.slider.slideTo(index);
    }

    onSlideChange(swiper) {
        this.removeListItemActiveClass();
        this.listItem.eq(swiper.activeIndex).addClass(this.classes.active);
    }
}

// Init on document ready
let apartment;
$(function () {
    $('.js-apartment').each(function() {
        apartment = new Apartment(this);
    });
});
