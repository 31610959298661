import 'swiper/css';

// modules styles
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './slider.scss';

import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

/**
 * In other component:
 * include: import { Slider } from '../slider/slider';
 * inside init(): this.slider = new Slider(this.element[0].querySelector('.js-slider'));
 * then can use: this.slider.on('slideChange', this.onSlideChange.bind(this));
 */

export class Slider {
    constructor(target, options) {
        this.element = $(target);

        this.settings = {
            modules: [Navigation, Pagination, Autoplay],
            loop: false,
            effect: 'fade',
            speed: 900,
            fadeEffect: {
                crossFade: true
            },
            navigation: {
                nextEl: this.element[0].querySelector('.swiper-button-next'),
                prevEl: this.element[0].querySelector('.swiper-button-prev'),
            },
            pagination: {
                el: this.element[0].querySelector('.swiper-pagination'),
                type: 'bullets',
                clickable: true,
            },
            simulateTouch: false,
        }

        if (options) {
            this.settings = options;
        }

        return new Swiper(this.element[0], this.settings);
    }
}
