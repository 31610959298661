import './hero.scss';

export class Hero {
    constructor(target) {
        this.element = $(target);
        this.hero    = $('.hero');
        this.init();
    }

    init() {
        $(window).on('scroll load', this.scrollHandler.bind(this));
    }

    scrollHandler() {
        this.animateHeroImg();
    }

    animateHeroImg() {
        const heroImgBottom = $(window).height() - this.hero.offset().top;

        setTimeout(() => {
            if ($(window).scrollTop() <= heroImgBottom && !this.hero.hasClass('is-animating')) {
                this.hero.addClass('is-animating');
            }
        }, 200);
    }

}

// Init on document ready
let hero;
$(function() {
    $('.hero').each(function() {
        hero = new Hero(this);
    });
});
