import './search-form.scss';

import {postAjax} from '../helpers/helpers';

export class SearchForm {
    constructor(target) {
        this.classes = {
            formClass: 'search-form__form',
            inputClass: 'search-form__input',
            resultsClass: 'search-form__results',
            loaderClass: 'search-form__loader',
        };

        this.element = $(target);
        this.input = $('.' + this.classes.inputClass);
        this.form = $('.' + this.classes.formClass);
        this.results = $('.' + this.classes.resultsClass);
        this.loader = $('.' + this.classes.loaderClass);

        this.init();
    }

    init() {
        this.input.on('keyup', this.searchHandler.bind(this));
    }

    searchHandler() {
        const val = this.input.val();

        if (val.length > 2) {
            this.results.empty();
            this.loader.show();

            postAjax(window.estpress.homeUrl + '?s=' + val, '', (data) => {
                this.loader.hide();
                this.results.empty();
                this.results.html(data);
            });
        } else {
            this.results.empty();
        }
    }
}

// Init on document ready
let searchform;
$(function () {
    $('.js-search-form').each(function() {
        searchform = new SearchForm(this);
    });
});
