/**
 * Polyfills
 */
import 'core-js/features/function/name';
import 'core-js/features/object/assign';
import 'core-js/features/promise';

/**
 * Import some components before everything else
 */
import './components/helpers/helpers';
import './components/reset/reset';
import './components/typography/typography';

// require all .js files in components directory
const components = require.context("./components/", true, /^\.\/(?!.*admin).*\.js$/);

components.keys().forEach((key) => {
    const component = components(key);

    if (component.default) {
        module.exports[component.default.name] = component.default;
    }
});

// require all .js files in modules directory
// const modules = require.context("./modules/", true, /^\.\/.*\.js$/);

// modules.keys().forEach((key) => {
//     const component = modules(key);

//     if (component.default) {
//         module.exports[component.default.name] = component.default;
//     }
// });
