import 'bootstrap/js/dist/modal';
import './modal.scss';

export class Modal {
    constructor(target) {
        this.element = $(target);

        this.classes = {
            modalClass: 'modal',
        };

        this.hash                 = window.location.hash;
        this.modalDismissSelector = '[data-dismiss="modal"]';
        this.modalButtonSelector  = '[data-bs-target="' + this.hash + '"]';

        this.init();
    }

    init() {
        let that = this;

        this.openModalOnLoad();
        this.urlHash();
        // $(window).on('load', this.openModalOnLoad.bind(this));

        $(document).on('click', this.modalDismissSelector, this.closeModal.bind(this));
    }

    closeModal(event) {
        let $el = $(event.currentTarget);
        $el.closest('.' + this.classes.modalClass).modal('hide');
    }

    openModalOnLoad() {
        if ($(this.hash).length && $(this.modalButtonSelector).length) {
            if (typeof $(this.modalButtonSelector).get(0).click === 'function') {
                $(this.modalButtonSelector).get(0).click();
            }
        }
    }

    urlHash() {
        if (document.querySelector('.' + this.classes.modalClass) !== null) {
            document.querySelectorAll('.' + this.classes.modalClass).forEach((item) => {
                item.addEventListener('show.bs.modal', (evt) => {
                    var el = evt.target;
                    history.pushState(null, null, '#' + el.getAttribute('id'));
                });
            });
        }
    }
}

// Init on document ready
let modal;
$(function () {
    $('.modal').each(function() {
        modal = new Modal(this);
    });
});
