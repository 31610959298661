import './gallery-page.scss';

import { Slider } from '../slider/slider';

export class GalleryPage {
    constructor(target) {
        this.element = $(target);

        this.classes = {
            slider: 'gallery-page__swiper',
            thumbItem: 'gallery-page__thumbnails-item',
            thumb: 'gallery-page__thumbnails-img',
            categoryItem: 'gallery-page__categories-list-item',
            categoryLink: 'gallery-page__categories-list-link',
            active: 'is-active',
        }

        this.categoryAttribute = 'data-gallery-category';

        this.thumbItem    = this.element.find('.' + this.classes.thumbItem);
        this.thumb        = this.element.find('.' + this.classes.thumb);
        this.categoryLink = this.element.find('.' + this.classes.categoryLink);

        this.init();
    }

    init() {
        this.thumb.on('click', this.onGalleryThumbClick.bind(this));
        this.categoryLink.on('click', this.onCategoryClick.bind(this));

        if (this.element.find('.js-slider').length) {
            this.slider = new Slider(this.element[0].querySelector('.js-slider'));
            this.slider.on('slideChange', this.onSlideChange.bind(this));
        }
    }

    thumbsRemoveActiveClass() {
        this.element.find('.' + this.classes.thumbItem).removeClass(this.classes.active);
    }

    onGalleryThumbClick(event) {
        const target        = $(event.target);
        const thumbItem     = target.closest('.' + this.classes.thumbItem);
        const slideIndexInt = thumbItem.index();
        const category      = thumbItem.attr(this.categoryAttribute);

        this.slider.slideTo(slideIndexInt);
        this.thumbsRemoveActiveClass();
        thumbItem.addClass(this.classes.active);
        this.categoryActiveState(category);
    }

    categoryActiveState(currentCategory) {
        this.element.find('.' + this.classes.categoryItem + '[' + this.categoryAttribute + ']').removeClass(this.classes.active);
        this.element.find('.' + this.classes.categoryItem + '[' + this.categoryAttribute + '=' + currentCategory + ']').addClass(this.classes.active);
    }

    onCategoryClick(event) {
        const target   = $(event.target);
        const category = target.closest('.' + this.classes.categoryItem).attr(this.categoryAttribute);

        this.element.find('.' + this.classes.thumbItem + '[' + this.categoryAttribute + '=' + category + '] .' + this.classes.thumb).first().click();
    }

    onSlideChange(event) {
        const activeIndex = event.activeIndex;
        const thumbItem   = this.thumbItem.eq(activeIndex);
        const category    = thumbItem.attr(this.categoryAttribute);

        this.thumbsRemoveActiveClass();
        thumbItem.addClass(this.classes.active);
        this.categoryActiveState(category);
    }
}

// Init on document ready
let galleryPage;
$(function () {
    $('.js-gallery-page').each(function() {
        galleryPage = new GalleryPage(this);
    });
});
