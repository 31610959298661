import './modal-enquiry.scss';

$('.modal-enquiry').on('show.bs.modal hide.bs.modal', (event) => {
    const relatedTarget = $(event.relatedTarget);
    const modal         = $(event.currentTarget);
    const house         = event.type === 'hide' ? '' : relatedTarget.data('house');

    modal.find('[data-name="house"]').val(house);
});

window.repositionModalTargets = $('.modal-enquiry').find('.cta-block__img, .cta-block__content');

const repositionModalImage = () => {
    window.viewportWidthChange = 767;

    const isChangeDown = !window.isChangeDown && window.innerWidth < window.viewportWidthChange;
    const isChangeUp   = !window.isChangeUp && window.innerWidth > window.viewportWidthChange;

    if (!isChangeDown && !isChangeUp) {
        return;
    }

    const targetLocation = isChangeUp ? $('.modal-enquiry .cta-block__left') : $('.modal-enquiry .form-block');

    window.repositionModalTargets.detach()[isChangeUp ? 'appendTo' : 'insertAfter'](targetLocation);

    window.isChangeDown = isChangeDown;
    window.isChangeUp   = isChangeUp;
};

$(() => {
    repositionModalImage();
});

$(window).on('resize', repositionModalImage);

